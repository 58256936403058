import { useState, useRef } from 'react'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import botAPI from 'api/bot-api'

const Cell = styled.div`
  max-width: 200px;
  width: max-content;
  max-height: 200px;
  overflow: auto;
  text-align: left;
`

export const NotesCell = ({ id, value }) => {
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef()

  const cancel = () => {
    setEdited(false)
    inputRef.current.value = value
  }

  const submit = async () => {
    setLoading(true)

    try {
      await botAPI.updateBotNotes(id, inputRef.current.value)
    } catch (e) {
      console.log(e)
      cancel()
    } finally {
      setEdited(false)
      setLoading(false)
    }
  }

  return <Cell>
    <TextField
      multiline
      rows={2}
      disabled={loading}
      defaultValue={value}
      variant="standard"
      inputRef={inputRef}
      InputProps={{
        onFocus: () => setEdited(true),
        endAdornment: edited && (
          <InputAdornment position="end">
            <IconButton
              onClick={submit}
              edge="end"
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              onClick={cancel}
              edge="end"
            >
              <CancelIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </Cell>
}
