import { Paper } from '@mui/material'
import Button from 'components/Button/Button'
import DetailedBookkeepingTable from 'pages/Bookkeeping/components/DetailedBookkeepingTable'
import { BotTable, UNARCHIVED } from 'pages/Bots/BotTable'
import { useBotApi, usePagination } from 'pages/Bots/hooks'
import { useNavigate, useParams } from 'react-router'

const BotDetails = () => {
  const { page, incrementPage, decrementPage, setStartPage, pageSize, changePageSize, tableRef } = usePagination({
    name: 'detailed-bot-table',
  })

  const { botId } = useParams()

  const { bots, isFetching, setBots, fetchBots, isTableIsFetching, filterBots, exchanges, isLastPage } = useBotApi(
    UNARCHIVED,
    true,
    page,
    pageSize,
    botId ? { field: 'id', value: Number(botId) } : null
  )

  const isBotExist = bots.length > 0
  const navigate = useNavigate()

  return (
    <Paper
      sx={{
        '& .MuiPaper-root': { width: '99vw', maxWidth: '99vw' },
      }}
    >
      <BotTable
        tableRef={tableRef}
        botsType={UNARCHIVED}
        data={bots}
        setData={setBots}
        isFetching={isFetching}
        refreshBots={fetchBots}
        exchanges={exchanges}
        setIsFetching={isTableIsFetching}
        filterBots={filterBots}
        pagination={{
          page,
          incrementPage,
          decrementPage,
          setStartPage,
          pageSize,
          changePageSize,
          tableRef,
          isLastPage,
        }}
        isFullTable={botId ? false : true}
      />
      {isBotExist && <DetailedBookkeepingTable detailed filterTableBy={{ field: 'requestId', value: Number(botId) }} />}
      {!isBotExist && (
        <Button variant={'contained'} color={'primary'} onClick={() => navigate(`/bot-details`)}>
          {'Return to Bots list'}
        </Button>
      )}
    </Paper>
  )
}

export default BotDetails
