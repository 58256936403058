import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import { useForm, Controller } from "react-hook-form"

import { useCallback } from 'react'

const FIELD_OPTIONS = {
  required: true,
  validate: (v) => typeof v === 'string' ? v.trim().length > 1 : v !== null && v !== undefined,
}

export const Rule = ({ rule, save, close }) => {
  const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues: rule });
  const onSubmit = useCallback(async (data) => {
    await save(data)

    close()
  }, [save, close])

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
    >
      <Card sx={{ maxWidth: 310 }}>
        <CardContent>
          <Typography variant="h5" component="div" mb={2} id={'edit_popup_title'}>
            Update {rule?.id} rule
          </Typography>
          <Controller
            name="bot_id"
            control={control}
            render={({ field }) =>
              <TextField
                disabled
                label="bot"
                error={!!errors.bot_id}
                helperText={errors.bot_id && "invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="amount"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="amount"
                error={!!errors.amount}
                helperText={errors.amount && "invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="min_threshold"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="min_threshold"
                error={!!errors.min_threshold}
                helperText={errors.min_threshold && "invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="period"
            control={control}
            rules={FIELD_OPTIONS}
            render={({ field }) =>
              <TextField
                label="period"
                error={!!errors.period}
                helperText={errors.period && "invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="enabled"
            control={control}
            render={({ field }) =>
              <TextField
                disabled
                label="enabled"
                error={!!errors.enabled}
                helperText={errors.enabled && "invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="from"
            control={control}
            render={({ field }) =>
              <TextField
                disabled
                label="from"
                error={!!errors.from}
                helperText={errors.from && "invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="to"
            control={control}
            render={({ field }) =>
              <TextField
                disabled
                label="to"
                error={!!errors.to}
                helperText={errors.to && "invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="coin"
            control={control}
            render={({ field }) =>
              <TextField
                disabled
                label="coin"
                error={!!errors.coin}
                helperText={errors.coin && "invalid"}
                {...field}
              />
            }
          />
          <Controller
            name="dex_wallet"
            control={control}
            render={({ field }) =>
              <TextField
                label="Dex Wallet"
                error={!!errors.dex_wallet}
                helperText={errors.dex_wallet && "invalid"}
                {...field}
              />
            }
          />
        </CardContent>
        <CardActions>
          <Button size="small" color='primary' type="submit" id='submit_button'>Submit</Button>
          <Button size="small" onClick={close}>Cancel</Button>
        </CardActions>
      </Card>
    </Box>
  )
}
