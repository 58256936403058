import { Divider, IconButton, Paper, Tooltip, Switch, Select, MenuItem, Typography, FormControlLabel } from '@mui/material'
import Button from 'components/Button/Button'
import { useEffect, useCallback, useState } from 'react'
import Preloader from 'components/Preloader/Preloader.jsx'
import moment from 'moment'

import { BotEdit } from './BotEdit'
import { useBotEditApi, useBotGraph, PAGE_SIZES } from './hooks'

import s from './BotTable.module.css'
import { ARCHIVED, UNARCHIVED } from './BotTable.jsx'
import { MOBILE_COLUMNS } from './mobile-columns.jsx'
import { BotToggleByExchange } from './BotToggleByExchange/index.jsx'
import { BotFilterByExchange } from './BotFilterByExchange/index.jsx'
import { useBotBalanceLevelingApi } from './BotEditor/BalanceLeveling/hooks.js'

const Rule = ({ rule, disableRule, enableRule, refreshBots }) => {
  if (!rule) {
    return (
      <div>&nbsp;</div>
    )
  }

  const isCex = /_spot/gi.test(rule.from)

  return (
    <Tooltip title={rule.from}>
      <div>
        <FormControlLabel
          control={
            <Switch
              size='small'
              color='primary'
              checked={rule.enabled}
              onChange={async () => {
                if (rule.enabled) {
                  await disableRule(rule.id)
                } else {
                  await enableRule(rule.id)
                }

                refreshBots()
              }}
            />
          }
          label={isCex ? 'CEX' : 'DEX'}
        />
      </div>
    </Tooltip>
  )
}

const Row = ({ bot, now, columns, itemProps, archived, enableRule, disableRule, refreshBots }) => {
  const [showError, toggleError] = useState(false)
  const isOn = bot?.status

  return (
    <div className='column' style={{ flex: 1, gap: 8 }}>
      <div className={'flex-row align-center'}>
        <b>Bot ID: {bot.id}</b>
        {!archived && (
          <div>
            <Tooltip title={`Press to ${isOn ? 'Deactivate' : 'Activate'}`}>
              <span>
                <Switch
                  size='small'
                  color='primary'
                  checked={isOn}
                  disabled={itemProps.isStatusChanging}
                  onChange={() => {
                    itemProps.switchBotStatus(bot.id, isOn)
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title={'Archive'}>
              <span>
                <IconButton
                  onClick={(e) => {
                    itemProps.openConfirmArchivation(e, bot.id)
                  }}
                  size={'small'}
                  style={{ margin: '0 8px' }}
                  disabled={itemProps.botsType === ARCHIVED}
                >
                  <i className={`fas fa-archive blue`} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  itemProps.openBotEditor({ id: bot.id })
                }}
                size={'small'}
                style={{ margin: '0 8px' }}
              >
                <i className='fas fa-pen' />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <div className={'flex-row flex-wrap'}>
        Balance Leveling:&nbsp;&nbsp;&nbsp;{bot.leveling_rules?.map(rule => (
          <Rule rule={rule} enableRule={enableRule} disableRule={disableRule} refreshBots={refreshBots} />
        ))}
      </div>
      <div className={'flex-row flex-wrap'} style={{ gap: 4 }} id={'bot_mobile_first_row'}>
        {columns.firstRow.map((column) => (
          <Column
            key={column.accessor + bot.id}
            itemProps={itemProps}
            value={bot[column.accessor]}
            row={bot}
            {...column}
          />
        ))}
      </div>
      <div className={'flex-row flex-wrap'} style={{ gap: 4 }} id={'bot_mobile_second_row'}>
        {columns.secondRow.map((column) => (
          <Column
            key={column.accessor + bot.id}
            itemProps={itemProps}
            value={bot[column.accessor]}
            row={bot}
            {...column}
          />
        ))}
      </div>
      {bot.bot_error?.timestamp && (
        <div>
          <Typography
            onClick={() => toggleError(err => !err)}
            variant="caption"
            display="block"
            gutterBottom
          >
            got an error {moment(bot.bot_error.timestamp).from(now)}
          </Typography>
          {showError && <Typography variant="caption" display="block" gutterBottom>{bot.bot_error.error}</Typography>}
        </div>
      )}
      <Divider height={1} />
    </div>
  )
}

const Column = ({ Header, accessor, Cell, row, value, itemProps }) => {
  return (
    <div className='flex-column'>
      <div className={s.mobileColumnHeader} id={'column_title'}>{Header}</div>
      <div className={s.mobileColumnCell} id={'column_data'}>
        {Cell ? <Cell row={row} value={value} id={accessor} {...itemProps} /> : value}
      </div>
    </div>
  )
}

export const MobileBotTable = ({
  botsType = UNARCHIVED,
  pagination,
  tableRef,
  data,
  exchanges,
  setData,
  isFetching,
  filterBots,
  refreshBots,
  setIsFetching,
  skipColumns = [],
}) => {
  const { enableRule, disableRule } = useBotBalanceLevelingApi({ skipFetch: true })

  const [editedBot, botArchiveApi, botStatusApi, botPopup, summaryBotsStatus, cacheBot] = useBotEditApi(
    data,
    setData,
    botsType,
    refreshBots
  )
  const [graph, openGraph, closeGraph] = useBotGraph()

  const itemProps = {
    isStatusChanging: botStatusApi.editing,
    openBotEditor: botStatusApi.openBotEditor,
    switchBotStatus: botStatusApi.switchBotStatus,
    changeBotArchive: botArchiveApi.changeBotArchive,
    botsType,
    openConfirmArchivation: botArchiveApi.openConfirmArchivation,
    openGraph,
  }

  useEffect(() => {
    if (cacheBot.enabled) {
      cacheBot.clearBotsStatuses()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.pageSize])

  const filterByExchange = useCallback((exchange) => {
    cacheBot.clearBotsStatuses('Bots list updated!')

    if (!exchange) {
      filterBots(null)
    } else {
      filterBots({ field: 'exchange_to.name', value: exchange })
    }
  }, [filterBots, cacheBot])

  const tableDisabled = isFetching || botStatusApi.editing

  const toggleStyle =
    s[`botStatusSwitchToggle_${summaryBotsStatus === null ? 'center' : summaryBotsStatus === true ? 'right' : 'left'}`]

  const now = moment()

  return (
    <Paper className={s.mobilePaper + ' flex-column'} style={{ padding: 12, width: '100%' }} elevation={2} id='bot_details_table'>
      {botsType === UNARCHIVED && (
        <>
          <div className={s.mobileHeading}>
            <div className={s.createBot} onClick={botStatusApi.openBotEditor} id='create_bot_icon'>
              <i className='fas fa-plus' /> Create a bot
            </div>
          </div>
          <div className={s.mobileHeading}>
            <div className={s.botStatusSwitch}>
              <div className={s.createBot}>Bots status:</div>
              <div className={`${s.botStatusSwitchToggle} ${toggleStyle}`}>
                <button
                  className={s.botStatusSwitchToggleButton}
                  disabled={summaryBotsStatus === false || tableDisabled}
                  onClick={botStatusApi.disableAll}
                  id='disable_all_bot_icon'
                >
                  <i className='fas fa-times' />
                </button>
                <div className={s.botStatusSwitchToggleButton}></div>
                <button
                  className={s.botStatusSwitchToggleButton}
                  disabled={summaryBotsStatus === true || tableDisabled}
                  onClick={botStatusApi.enableAll}
                  id='enable_all_bots_link'
                >
                  <i className='fas fa-check' />
                </button>
              </div>
              <span className={s.createBotPopover}>On or Off all bots</span>
            </div>
            <button
              className={cacheBot.enabled ? `${s.createBot} ${s.createBotSuccess}` : s.createBot}
              onClick={cacheBot.saveBotsStatuses}
              id='save_bot_status_icon'
            >
              <i className='fas fa-save' /> {cacheBot.enabled ? 'Saved' : 'Save  '}
              <span className={s.createBotPopover}>Save bots statuses</span>
            </button>
            <button className={s.createBot} onClick={cacheBot.rollbackBotsStatuses} id='rollback_bot_status_icon' disabled={!cacheBot.enabled}>
              <i className='fas fa-share' /> Rollback
              <span className={s.createBotPopover}>Apply saved bots statuses</span>
            </button>
            <button
              className={cacheBot.enabled ? `${s.createBot} ${s.createBotClear}` : s.createBot}
              onClick={cacheBot.clearBotsStatuses}
              id='clear_bot_status_icon'
              disabled={!cacheBot.enabled}
            >
              <i className='fas fa-times' /> Clear
              <span className={s.createBotPopover}>Clear last cached statuses</span>
            </button>
          </div>
          <div className={s.mobileHeading}>
            <div className={s.createBot} id='filter_by_exchange'>
              <BotFilterByExchange exchanges={exchanges} filterByExchange={filterByExchange} bots={data} />
            </div>
            <div className={s.createBot} id='status_by_exchange'>
              <BotToggleByExchange disabled={tableDisabled} setIsFetching={setIsFetching} refreshBots={refreshBots} bots={data} />
            </div>
          </div>
        </>
      )}
      {isFetching && <Preloader />}
      <div ref={tableRef} className='column' style={{ gap: 12 }} id={'bot_list'}>
        {data?.sort((a, b) => b.id - a.id).map((row) => (
          <Row
            key={row.id}
            archived={botsType === ARCHIVED}
            now={now}
            bot={row}
            columns={MOBILE_COLUMNS}
            itemProps={itemProps}
            enableRule={enableRule}
            disableRule={disableRule}
            refreshBots={refreshBots}
          />
        ))}
      </div>
      <div className={s.pagination}>
        <Button onClick={pagination.decrementPage} disabled={isFetching || pagination.page === 1} id='prev_page'>
          Prev
        </Button>
        <div>{pagination.page}</div>
        <div>
          <Select value={pagination.pageSize} onChange={pagination.changePageSize}>
            {PAGE_SIZES.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={pagination.incrementPage} disabled={isFetching || pagination.isLastPage} id='next_page'>
            Next
          </Button>
        </div>
      </div>

      <BotEdit
        botsType={botsType}
        refreshBots={refreshBots}
        botPopup={botPopup}
        botGraphApi={{
          graph,
          openGraph,
          closeGraph,
        }}
        editedBot={editedBot}
        botArchiveApi={botArchiveApi}
        botStatusApi={botStatusApi}
      />
    </Paper>
  )
}
