import { Paper, Typography, Select, MenuItem } from '@mui/material'
import Button from 'components/Button/Button'
import Table from 'components/Table/Table.jsx'
import { useMemo, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { BotEdit } from './BotEdit'
import { useBotEditApi, useBotGraph, PAGE_SIZES, useBotBulkActions } from './hooks'
import { BotToggleByExchange } from './BotToggleByExchange'
import { BotFilterByExchange } from './BotFilterByExchange'
import { botErrorSort } from 'utilities/sorting'
import { getColumns } from './columns'
import { useBotBalanceLevelingApi } from './BotEditor/BalanceLeveling/hooks'
import s from './BotTable.module.css'
import { SelectAllActions } from './SelectAll'
import { BlockedBots } from './BlockedBots/BlockedBots'

export const UNARCHIVED = 'unarchived'
export const ARCHIVED = 'archived'

export const BotTable = ({
  botsType = UNARCHIVED,
  data,
  tableRef,
  pagination,
  exchanges,
  setData,
  isFetching,
  refreshBots,
  setIsFetching,
  filterBots,
  skipColumns = [],
  isFullTable = true,
}) => {
  const { selectBot, selectedAll, selectAllBots, selectedBots, archiveSelected, enableSelected, disableSelected } =
    useBotBulkActions({ data, setIsFetching, refreshBots })

  const columns = useMemo(() => getColumns(botsType, undefined, undefined, skipColumns), [botsType, skipColumns])
  const navigate = useNavigate()
  const { enableRule, disableRule } = useBotBalanceLevelingApi({ skipFetch: true })

  const [editedBot, botArchiveApi, botStatusApi, botPopup, summaryBotsStatus, cacheBot] = useBotEditApi(
    data,
    setData,
    botsType,
    refreshBots
  )
  const [graph, openGraph, closeGraph] = useBotGraph()

  useEffect(() => {
    if (cacheBot.enabled) {
      cacheBot.clearBotsStatuses()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.pageSize])

  const toggleStyle =
    s[`botStatusSwitchToggle_${summaryBotsStatus === null ? 'center' : summaryBotsStatus === true ? 'right' : 'left'}`]

  const filterByExchange = useCallback(
    (exchange) => {
      cacheBot.clearBotsStatuses('Bots list updated!')

      if (!exchange) {
        filterBots(null)
      } else {
        filterBots({ field: 'exchange_to.name', value: exchange })
      }
    },
    [filterBots, cacheBot]
  )
  const tableDisabled = isFetching || botStatusApi.editing
  const noCache = !cacheBot.enabled

  return (
    <Paper className={s.paper + ' flex-column'} elevation={2} id='bot_details_table'>
      <div className={s.heading}>
        <div className={s.headingTitle}>
          <Typography className={s.headingTitleText} variant={'h5'}>
            {botsType === ARCHIVED ? 'Archived bots' : 'Bot List'}
          </Typography>
        </div>
        {botsType === UNARCHIVED && (
          <div className='flex-row' style={{ gap: 12 }}>
            <div className={s.createBot} id='filter_by_exchange'>
              <BotFilterByExchange exchanges={exchanges} filterByExchange={filterByExchange} bots={data} />
            </div>
            <div className={s.createBot} id='status_by_exchange'>
              <BotToggleByExchange
                disabled={tableDisabled || noCache}
                setIsFetching={setIsFetching}
                refreshBots={refreshBots}
                bots={data}
              />
            </div>
            <button
              className={s.createBot}
              disabled={tableDisabled}
              onClick={() => navigate('/archived-bots')}
              id='archived_bots_link'
            >
              <i className='fas fa-archive' /> Archived bots
            </button>
            <button
              className={s.createBot}
              disabled={tableDisabled}
              onClick={botStatusApi.openBotEditor}
              id='create_bot_icon'
            >
              <i className='fas fa-plus' /> Create a bot
            </button>
            <div className={s.botStatusSwitch}>
              {(tableDisabled || noCache) && <div className={s.botStatusSwitchDisabled} />}
              <div className={s.createBot}>Bots status:</div>
              <div className={`${s.botStatusSwitchToggle} ${toggleStyle}`}>
                <button
                  className={s.botStatusSwitchToggleButton}
                  disabled={tableDisabled || summaryBotsStatus === false}
                  onClick={botStatusApi.disableAll}
                  id='disable_all_bot_icon'
                  title='Switch off all bots'
                >
                  <i className='fas fa-times' />
                </button>
                <div className={s.botStatusSwitchToggleButton}>{/* <i className='fas fa-minus' /> */}</div>
                <button
                  className={s.botStatusSwitchToggleButton}
                  disabled={tableDisabled || summaryBotsStatus === true}
                  onClick={botStatusApi.enableAll}
                  id='enable_all_bots_link'
                  title='Switch on all bots'
                >
                  <i className='fas fa-check' />
                </button>
              </div>
              <span className={s.createBotPopover}>On or Off all bots</span>
            </div>
            <button
              className={cacheBot.enabled ? `${s.createBot} ${s.createBotSuccess}` : s.createBot}
              onClick={cacheBot.saveBotsStatuses}
              disabled={tableDisabled}
              id='save_bot_state'
            >
              <i className='fas fa-save' /> {cacheBot.enabled ? 'Saved' : 'Save  '}
              <span className={s.createBotPopover}>Save bots statuses</span>
            </button>
            <button
              className={s.createBot}
              onClick={cacheBot.rollbackBotsStatuses}
              id='rollback_bot_state'
              disabled={tableDisabled || !cacheBot.enabled}
            >
              <i className='fas fa-share' /> Rollback
              <span className={s.createBotPopover}>Apply saved bots statuses</span>
            </button>
            <button
              className={cacheBot.enabled ? `${s.createBot} ${s.createBotClear}` : s.createBot}
              onClick={cacheBot.clearBotsStatuses}
              id='clear_bot_state'
              disabled={tableDisabled || !cacheBot.enabled}
            >
              <i className='fas fa-times' /> Clear
              <span className={s.createBotPopover}>Clear last cached statuses</span>
            </button>
          </div>
        )}
      </div>
      {botsType === UNARCHIVED && (
        <SelectAllActions
          selectedCount={selectedBots.length}
          archiveSelected={archiveSelected}
          enableSelected={enableSelected}
          disableSelected={disableSelected}
        />
      )}
      {isFullTable && <BlockedBots></BlockedBots>}
      <Table
        data={data}
        tableRef={tableRef}
        columns={columns}
        loading={tableDisabled}
        hiddenColumns={botsType === ARCHIVED ? ['opportunity', 'dexPrice', 'dexTotal'] : []}
        tableStyle={{ height: isFullTable ? 'calc(100vh - 300px)' : '170px', margin: 0, minHeight: 300 }}
        initialState={{
          sortBy: [{ id: 'id', desc: true }],
        }}
        sortFns={{ botErrorSort }}
        meta={{ selectAllBots, selectedAll }}
        cellProps={{
          botsType,
          openConfirmArchivation: botArchiveApi.openConfirmArchivation,
          openGraph,
          isStatusChanging: botStatusApi.editing,
          openBotEditor: botStatusApi.openBotEditor,
          switchBotStatus: botStatusApi.switchBotStatus,
          changeBotArchive: botArchiveApi.changeBotArchive,
          enableBotRule: enableRule,
          disableBotRule: disableRule,
          selectBot,
          selectedBots,
          refreshBots,
        }}
      />
      {isFullTable && (
        <div className={s.pagination}>
          <Button onClick={pagination.decrementPage} disabled={isFetching || pagination.page === 1} id='prev_page'>
            Prev
          </Button>
          <div>{pagination.page}</div>
          <div>
            <Select value={pagination.pageSize} onChange={pagination.changePageSize}>
              {PAGE_SIZES.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
            <Button onClick={pagination.incrementPage} disabled={isFetching || pagination.isLastPage} id='next_page'>
              Next
            </Button>
          </div>
        </div>
      )}
      <BotEdit
        botsType={botsType}
        refreshBots={refreshBots}
        botPopup={botPopup}
        botGraphApi={{
          graph,
          openGraph,
          closeGraph,
        }}
        editedBot={editedBot}
        botArchiveApi={botArchiveApi}
        botStatusApi={botStatusApi}
      />
    </Paper>
  )
}
