import { useState, useEffect, useCallback, useMemo } from 'react'
import { balanceLevelingApi } from 'api/balance-leveling-api'
import { useSnackbar } from 'notistack'

export const useBotBalanceLevelingApi = ({ botId, skipFetch } = {}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [rulesList, setRules] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const fetch = useCallback(async () => {
    setIsFetching(true)

    const res = await balanceLevelingApi.getLevelingRules(1, 5000)

    setRules(res)
    setIsFetching(false)
  }, [])

  useEffect(() => {
    if (!skipFetch) {
      fetch()
    }
  }, [fetch, skipFetch])

  const enableRule = useCallback(async (ruleId) => {
    try {
      await balanceLevelingApi.enableRule(ruleId)
      if (!skipFetch) {
        fetch()
      }
      enqueueSnackbar(`rule ${ruleId} enabled`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }
  }, [enqueueSnackbar, skipFetch, fetch])

  const disableRule = useCallback(async (ruleId) => {
    try {
      await balanceLevelingApi.disableRule(ruleId)
      if (!skipFetch) {
        fetch()
      }
      enqueueSnackbar(`rule ${ruleId} disabled`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }
  }, [enqueueSnackbar, skipFetch, fetch])

  const updateRule = useCallback(async (rule) => {
    try {
      await balanceLevelingApi.updateRule(rule)
      if (!skipFetch) {
        fetch()
      }
      enqueueSnackbar(`rule ${rule.id} updated`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }
  }, [enqueueSnackbar, skipFetch, fetch])

  const rules = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return botId ? rulesList.filter(({ bot_id }) => bot_id.split('-')[0] == botId) : rulesList
  }, [botId, rulesList])

  return {
    rules,
    isFetching,
    updateRule,
    enableRule,
    disableRule,
  }
}
