import { fetch } from './'

const botAPI = {
  getBotList: (page, pageSize, globalFilters = {}) => {
    return fetch
      .get(`bot`, { searchParams: { page, pageSize, ...globalFilters } })
      .json()
      .catch((err) => console.log(err))
  },
  getBot: (botId) => {
    return fetch
      .get(`bot/${botId}`)
      .json()
      .catch((err) => console.log(err))
  },
  createBot: (botType, botData) => {
    return fetch.post(`bot/${botType}`, { json: botData })
  },
  updateBot: (id, botType, botData) => {
    return fetch.post(`bot/${botType}/${id}`, { json: botData })
  },
  updateBotNotes: (id, notes) => {
    return fetch.put(`notes/${id}`, { json: { notes } })
  },
  disableBots: (ids) => {
    return fetch
      .post(`bots-status/disable`, { json: ids.map(bot_id => ({ bot_id })) })
  },
  enableBots: (ids) => {
    return fetch
      .post(`bots-status/enable`, { json: ids.map(bot_id => ({ bot_id })) })
  },
  changeBotStatusByExchange: (exchange, status) => {
    return fetch
      .post(`bots-status`, { json: { exchange, status: status ? 'true' : 'false' } })
  },
  getArchivedBotList: (page, pageSize, globalFilters = {}) => {
    return fetch
      .get(`archived-bots`, { searchParams: { page, pageSize, ...globalFilters } })
      .json()
      .catch((err) => console.log(err))
  },
  archiveBot: (id) => {
    return fetch
      .post(`archive-bot/${id}`)
      .catch((err) => console.log(err))
  },
  archiveBots: (ids) => {
    return fetch
      .post(`archive-bots`, { json: ids.map(bot_id => ({ bot_id })) })
      .catch((err) => console.log(err))
  },
  unarchiveBot: (id) => {
    return fetch
      .post(`archive-bot`, { json: { id, status: 0 } })
      .json()
      .catch((err) => console.log(err))
  },
  getBotHistory: (id, page = 1) => {
    return fetch
      .get(`bot-history/${id}?page=${page}`)
      .json()
      .catch((err) => console.log(err))
  },
  getSignals: (page) => {
    return fetch
      .get(`bot-signals?page=${page}`)
      .json()
      .catch((err) => console.log(err))
  },
  getOpportunities: (page, pageSize, filters) => {
    return fetch
      .get(`bot-opportunities`, { searchParams: { page, pageSize, ...filters } })
      .json()
      .catch((err) => console.log(err))
  },
  getHistory: (page, pageSize, sortBy, filter, filters) => {
    const searchParams = {
      page,
      pageSize,
      ...filters,
    }

    if (sortBy?.id) {
      searchParams.sortedField = sortBy.id
      searchParams.sortedDirection = sortBy.desc ? 'desc' : 'asc'
    }

    if (filter?.id) {
      searchParams.filteredField = filter.id
      searchParams.filteredValue = filter.value
    }

    return fetch
      .get(`filtered-history`, { searchParams })
      .json()
      .catch((err) => console.log(err))
  },
  getChartData: (botId, startTime, endTime) => {
    return fetch
      .get(`chart`, {
        searchParams: {
          bot_id: botId,
          start_time: startTime,
          end_time: endTime,
        },
      })
      .json()
      .catch((err) => console.log(err))
  },
}

export default botAPI
