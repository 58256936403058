import { useState, useMemo, useCallback } from 'react'
import { useExchangesSelector } from 'redux/selectors/settings'

import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Dialog from '@mui/material/Dialog'
import Chip from '@mui/material/Chip'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import botAPI from 'api/bot-api'

const Stack = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SChip = styled(Chip)`
  margin-right: 5px;
  margin-bottom: 5px;
  width: 50px;
`

const ToggleBotsDialog = ({ onClose, value, botsByExchange, changeStatus }) => {
  return (
    <Dialog onClose={onClose} open={!!value}>
      <Card sx={{ maxWidth: 310 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Toggle bot statuses
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            List of bots to be disabled
          </Typography>
          <Stack
            flexWrap="wrap"
            direction="row"
            spacing={1}
            justifyContent="start"
            alignItems="center"
          >
            {value?.id && botsByExchange[value.id]?.filteredItems?.length && botsByExchange[value.id].filteredItems.map(bot => {
              return <SChip key={bot.id} label={`${bot.id}`} />
            })}
          </Stack>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => changeStatus(value?.id, false)}>Disable Bots</Button>
          <Button size="small" onClick={onClose}>Close</Button>
          <Button size="small" onClick={() => changeStatus(value?.id, true)}>Enable Bots</Button>
        </CardActions>
      </Card>
    </Dialog>
  )
}

export const BotToggleByExchange = ({ refreshBots, bots, setIsFetching, disabled }) => {
  const [value, setValue] = useState(null)
  const availableExchanges = useExchangesSelector()
  const [botsByExchange, exchanges] = useMemo(() => {
    const map = {}

    for (const { internal_name, name } of availableExchanges) {
      const filteredItems = bots.filter(bot => bot.exchange_from?.name === internal_name || bot.exchange_to?.name === internal_name)

      if (filteredItems.length) {
        map[internal_name] = { filteredItems, name }
      }
    }

    return [map, Object.keys(map).map(exch => ({ id: exch, name: map[exch].name, label: `${exch} (${map[exch].filteredItems.length})` }))]
  }, [bots, availableExchanges])

  const changeStatus = useCallback(async (exchange, flag) => {
    setValue(null)
    setIsFetching(true)
    await botAPI.changeBotStatusByExchange(exchange, flag)
    await refreshBots()
  }, [refreshBots, setValue, setIsFetching])

  return (
    <>
      <Autocomplete
        disablePortal
        openOnFocus
        blurOnSelect
        value={null}
        onChange={(_, newValue) => {
          setValue(newValue)
        }}
        options={exchanges}
        sx={{ width: 160 }}
        renderInput={(params) => <TextField {...params} label="Status By Exchange" />}
        size="small"
        disabled={disabled}
      />
      <ToggleBotsDialog
        value={value}
        onClose={() => setValue(null)}
        botsByExchange={botsByExchange}
        changeStatus={changeStatus}
      />
    </>
  )
}
