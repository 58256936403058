import { Alert, Backdrop, Fade, Modal, Paper, Popover, Snackbar, Typography } from '@mui/material'
import Button from 'components/Button/Button'

import { ARCHIVED } from './hooks'

import BotGraph from './BotGraph/BotGraph'
import BotEditor from './BotEditor/BotEditor'
import s from './BotTable.module.css'
export const BotEdit = ({ botsType, refreshBots, botPopup, botArchiveApi, botStatusApi, editedBot, botGraphApi }) => {
  return (
    <>
      {editedBot?.show && (
        <BotEditor
          archived={botsType === ARCHIVED}
          refreshBots={refreshBots}
          onClose={botStatusApi.closeBotEditor}
          fill={editedBot.fill}
          id={editedBot.id}
          show={true}
          setPopup={botPopup.setPopup}
        />
      )}
      <Snackbar
        open={!!botPopup.popup.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => botPopup.setPopup({ open: false })}
      >
        <Alert onClose={() => botPopup.setPopup({ open: false })} severity={botPopup.popup.severity}>
          <div className='column' style={{ whiteSpace: 'pre-wrap' }}>
            {botPopup.popup.message}
          </div>
        </Alert>
      </Snackbar>
      <Popover
        open={!!botArchiveApi.confirmArchivationAnchor}
        anchorEl={botArchiveApi.confirmArchivationAnchor}
        onClose={botArchiveApi.closeConfirmArchivation}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 6 }} className='column'>
          <Typography>Archive this bot?</Typography>
          <Button style={{ marginTop: 8, marginLeft: 'auto' }} size='small' onClick={botArchiveApi.changeBotArchive}>
            Confirm
          </Button>
        </div>
      </Popover>
      <Modal
        aria-labelledby='modal-graph'
        aria-describedby='modal-graph'
        className={s.modal}
        open={!!botGraphApi.graph.show}
        onClose={botGraphApi.closeGraph}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={botGraphApi.graph.show}>
          <Paper className={s.graph} id='bot_graph_popup'>
            <BotGraph
              botId={`${botGraphApi.graph.internalId}-${botGraphApi.graph.externalId}|${botGraphApi.graph.operationId}`.toLowerCase()}
              botLabel={`${botGraphApi.graph.internalId} | ${botGraphApi.graph.side}`}
              handleClose={botGraphApi.closeGraph}
            />
          </Paper>
        </Fade>
      </Modal>
    </>
  )
}
