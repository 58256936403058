import { useLocalStorage } from 'hooks/use-local-storage'
import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { botsActions } from 'redux/reducers/bots-reducer'
import { useSessionStorage } from 'hooks/use-session-storage'
import { useSnackbar } from 'notistack'
import { useExchangesSelector } from 'redux/selectors/settings'
import botAPI from 'api/bot-api'
import { useBotErrorsCount } from 'api/errors-api'
import { useOpportunities } from 'api/opportunities-api'
import { useOrders } from 'api/orders-api'
import { useLevelingRules } from 'api/balance-leveling-api'
import { globalFiltersQuerySelector } from 'redux/selectors/globalFilters'
import { useSelector } from 'react-redux'

export const UNARCHIVED = 'unarchived'
export const ARCHIVED = 'archived'

export const SUMMARY_BOTS_STATUS = null // true | false | null

export const useBotEditApi = (bots, setBots, botsType, fetchBots) => {
  const [cachedBotsStatuses, cacheBotsStatuses] = useLocalStorage('cache-bots', [], 15)
  const [summaryBotsStatus, setSummaryBotsStatus] = useState(SUMMARY_BOTS_STATUS)
  const [editing, setEditing] = useState(false)
  const [editBot, setEditBot] = useState(null)
  const [popup, setPopup] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  const [confirmArchivationAnchor, setConfirmAnchor] = useState(null)

  const openConfirmArchivation = (event, id) => {
    setConfirmAnchor(event.currentTarget)
    setEditBot({ id, fill: bots.find((bot) => bot.id === id), show: false })
  }

  const closeConfirmArchivation = () => {
    setConfirmAnchor(null)
  }

  const openBotEditor = ({ id }) => setEditBot({ id, fill: bots.find((bot) => bot.id === id), show: true })
  const closeBotEditor = () => setEditBot((prev) => ({ ...prev, show: false }))

  const saveBotsStatuses = useCallback(() => {
    cacheBotsStatuses(bots.map(({ id, status }) => ({ id, status })))
    setPopup((prev) => ({
      ...prev,
      open: true,
      message: 'Bots statuses saved!',
      severity: 'success',
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bots])

  const clearBotsStatuses = useCallback((msg) => {
    cacheBotsStatuses([], { skipHistory: true })
    setPopup((prev) => ({
      ...prev,
      open: true,
      message: typeof msg === 'string' ? msg : 'Bots statuses cleared!',
      severity: 'success',
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rollbackBotsStatuses = useCallback(async () => {
    setEditing(true)
    const enableBots = []
    const disableBots = []

    for (const bot of cachedBotsStatuses) {
      if (bot.status) {
        enableBots.push(bot.id)
      } else {
        disableBots.push(bot.id)
      }
    }

    try {
      await Promise.all([
        enableBots.length && botAPI.enableBots(enableBots),
        disableBots.length && botAPI.disableBots(disableBots),
      ])
      await fetchBots()
      cacheBotsStatuses([], { skipHistory: true }) // clear cache only after success update
      setPopup((prev) => ({
        ...prev,
        open: true,
        message: 'Bots statuses returned!',
        severity: 'success',
      }))
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
    }

    setEditing(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bots, cachedBotsStatuses, setPopup, setEditing, fetchBots])

  const switchBotStatus = useCallback(
    async (id, currentStatus) => {
      setEditing(true)

      try {
        const action = currentStatus
          ? botAPI.disableBots
          : botAPI.enableBots

        const response = await action([id])

        if (response.status === 200) {
          setBots((prev) => {
            const prevBots = [...prev]

            for (let i = 0; i < prevBots.length; i++) {
              const bot = prevBots[i]

              // eslint-disable-next-line eqeqeq
              if (bot.id == id) {
                prevBots[i] = {
                  ...bot,
                  status: !bot.status,
                }
              }
            }

            return prevBots
          })
          setPopup((prev) => ({
            ...prev,
            open: true,
            message: 'Bot status changed!',
            severity: 'success',
          }))
        } else {
          let error = ''

          if (response?.data) {
            for (let key of Object.keys(response.data)) {
              if (response.data[key] !== undefined) {
                error += `\n${response.data[key][0]}`
              }
            }
          }

          throw Error(error)
        }
      } catch (err) {
        setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
      } finally {
        setEditing(false)
      }
    },
    [setBots, setPopup, setEditing]
  )

  const changeBotArchive = useCallback(async () => {
    setConfirmAnchor(null)
    const botId = editBot?.id

    try {
      const response = botsType === UNARCHIVED ? await botAPI.archiveBot(botId) : await botAPI.unarchiveBot(botId)

      if (response.status === 200) {
        fetchBots()

        setPopup((prev) => ({
          ...prev,
          open: true,
          message: 'Bot archivation status changed!',
          severity: 'success',
        }))
      } else {
        let error = ''

        if (response?.data) {
          for (let key of Object.keys(response.data)) {
            if (response.data[key] !== undefined) {
              error += `\n${response.data[key][0]}`
            }
          }
        }

        throw Error(error)
      }
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
    }
  }, [setPopup, fetchBots, botsType, editBot])

  const switchAllStatuses = useCallback(
    async (allEnabled) => {
      setEditing(true)
      const action = allEnabled
        ? botAPI.enableBots
        : botAPI.disableBots

      try {
        await action(bots.map(({ id }) => id))
        await fetchBots()
      } catch (err) {
        setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
      }

      setEditing(false)
      setPopup((prev) => ({
        ...prev,
        open: true,
        message: 'Bots statuses changed!',
        severity: 'success',
      }))
    },
    [bots, fetchBots, setEditing, setPopup]
  )

  const disableAll = useCallback(() => {
    switchAllStatuses(false)
  }, [switchAllStatuses])

  const enableAll = useCallback(() => {
    switchAllStatuses(true)
  }, [switchAllStatuses])

  useEffect(() => {
    let enabledSummaryStatusesLength = 0

    let disabledSummaryStatusesLength = 0

    for (const bot of bots) {
      if (bot.status) {
        enabledSummaryStatusesLength += 1
      } else {
        disabledSummaryStatusesLength += 1
      }
    }

    if (enabledSummaryStatusesLength === bots.length) {
      setSummaryBotsStatus(true)
    } else if (disabledSummaryStatusesLength === bots.length) {
      setSummaryBotsStatus(false)
    } else {
      setSummaryBotsStatus(null)
    }
  }, [bots, setSummaryBotsStatus])

  return [
    editBot,
    { changeBotArchive, confirmArchivationAnchor, openConfirmArchivation, closeConfirmArchivation },
    { switchBotStatus, editing, disableAll, enableAll, openBotEditor, closeBotEditor },
    { popup, setPopup },
    summaryBotsStatus,
    { saveBotsStatuses, clearBotsStatuses, rollbackBotsStatuses, enabled: cachedBotsStatuses.length !== 0 },
  ]
}

export const PAGE_SIZES = [50, 100, 500, 1000, 2000, 5000, 10000]

export const usePagination = (params = {}) => {
  const start = params.start ?? 1
  const name = params.name ?? 'bot-table'

  const [page, setPage] = useState(start)
  const [pageSize, setPageSize] = useSessionStorage(`botsPageSize${name}`, PAGE_SIZES[0])
  const tableRef = useRef(null)

  const decrementPage = useCallback(() => {
    setPage((prevPage) => {
      if (prevPage > 1) {
        return prevPage - 1
      }

      return prevPage
    })

    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
  }, [])

  const incrementPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1)
    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
  }, [])

  const setStartPage = useCallback(() => {
    setPage(start)
  }, [start])

  const changePageSize = useCallback(
    (e) => {
      setPageSize(e.target.value)
      setStartPage()
    },
    [setPageSize, setStartPage]
  )

  return { page, incrementPage, decrementPage, setStartPage, pageSize, changePageSize, tableRef }
}

export const useBotApi = (botsType = UNARCHIVED, fetchOnMount = true, page, pageSize, filterBotBy = null) => {
  const dispatch = useDispatch()
  const exchangesList = useExchangesSelector()
  const opportunitiesQuery = useOpportunities()
  const ordersQuery = useOrders()
  const botErrors = useBotErrorsCount()
  const levelingRulesQuery = useLevelingRules()
  const [bots, setBots] = useState([])
  const [isLastPage, setLastPage] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const globalFilters = useSelector(globalFiltersQuerySelector)

  const opportunities = opportunitiesQuery.data
  const orders = ordersQuery.data
  const levelingRules = levelingRulesQuery.data

  const fetchBots = useCallback(async (botId = null) => {
    setIsFetching(true)

    const levelingRulesMap = levelingRules?.reduce((acc, rule) => {
      if (acc[rule.bot_id]) {
        acc[rule.bot_id].push(rule)
      } else {
        acc[rule.bot_id] = [rule]
      }

      return acc
    }, {})

    let data = {}

    if (botId?.field === "id") {
      data = await botAPI.getBot(botId.value)
    } else {
      // eslint-disable-next-line max-len
      data = await (botsType === UNARCHIVED ? botAPI.getBotList(page, pageSize, globalFilters) : botAPI.getArchivedBotList(page, pageSize, globalFilters))
    }

    const { classic_bots, triple_bots } = data || {}

    let res = []

    if (classic_bots) {
      for (const bot of classic_bots) {
        const botId = `${bot.id}-${bot.external_id.toLowerCase()}`
        const leveling_rules = levelingRulesMap[botId]

        res.push({
          ...bot,
          status: bot.status === 'true',
          dex_symbol_left: bot.classic_arbitrage[0]?.dex.left,
          dex_symbol_right: bot.classic_arbitrage[0]?.dex.right,
          cex_symbol_left: bot.classic_arbitrage[0]?.cex.left,
          cex_symbol_right: bot.classic_arbitrage[0]?.cex.right,
          exchange_from: {
            name: bot.classic_arbitrage[0]?.dex.exchange,
            network: bot.classic_arbitrage[0]?.dex.network,
            // pool: bot.classic_arbitrage[0]?.dex.pool,
          },
          exchange_to: {
            name: bot.classic_arbitrage[0]?.cex.exchange,
          },
          direction: bot.classic_arbitrage.map((item) => ({ side: item.side })),
          profit: bot.classic_arbitrage.map((item) => ({ profit: item.profit })),
          qn: bot.classic_arbitrage.map((item) => ({ amount: item.amount })),
          bot_error: bot.bot_error && {
            error: bot.bot_error.error,
            timestamp: bot.bot_error.timestamp,
            moment: moment(bot.bot_error.timestamp),
            momentUnix: moment(bot.bot_error.timestamp).unix(),
          },
          opportunities: opportunities[botId],
          orders: orders && orders[botId] ? orders[botId] : [],
          leveling_rules,
        })
      }
    }

    if (triple_bots) {
      for (const bot of triple_bots) {
        const botId = `${bot.id}-${bot.external_id.toLowerCase()}`
        const leveling_rules = levelingRulesMap[botId]

        res.push({
          ...bot,
          status: bot.status === 'true',
          direction: bot.triple_arbitrage.map((item) => ({ side: item.side })),
          profit: bot.triple_arbitrage.map((item) => ({ profit: item.profit })),
          qn: bot.triple_arbitrage.map((item) => ({ amount: item.amount })),
          bot_error: bot.bot_error && {
            error: bot.bot_error.error,
            timestamp: bot.bot_error.timestamp,
            moment: moment(bot.bot_error.timestamp),
            momentUnix: moment(bot.bot_error.timestamp).unix(),
          },
          opportunities: opportunities[botId],
          orders: orders && orders[botId] ? orders[botId] : [],
          leveling_rules,
        })
      }
    }

    setLastPage(res.length < pageSize)

    setBots(res)
    dispatch(botsActions.saveBotsList(Object.fromEntries(res.map((bot) => ([bot.id, bot])))))

    setIsFetching(false)
  }, [levelingRules, globalFilters, pageSize, dispatch, botsType, page, opportunities, orders])

  const [filter, filterBots] = useState(filterBotBy) // { field, value } { "field" : "id", "value" : 6254 }

  useEffect(() => {
    if (opportunities && orders && levelingRules) {
      fetchBots(filter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, opportunities, globalFilters, orders, levelingRules])

  const filteredBots = useMemo(() => {
    if (!filter?.field) {
      return bots
    }

    return bots.filter(bot => {
      if (filter.field === 'exchange_to.name' && bot.type === 'TRIPLE') {
        return get(bot, 'triple_arbitrage[0].step2.exchange') === filter.value
      }

      return get(bot, filter.field) === filter.value
    })
  }, [filter, bots])

  const exchanges = useMemo(() => {
    const map = {}
    const cexExchanges = exchangesList?.filter(({ is_dex }) => !is_dex) || []

    for (const { internal_name } of cexExchanges) {
      const filteredItems = bots.filter(bot => {
        if (bot.type === 'TRIPLE') {
          return get(bot, 'triple_arbitrage[0].step2.exchange') === internal_name
        }

        return bot.exchange_from?.name === internal_name || bot.exchange_to?.name === internal_name
      })

      if (filteredItems.length) {
        map[internal_name] = filteredItems
      }
    }

    return Object.keys(map).map(exch => ({ id: exch, label: `${exch} (${map[exch].length})` }))
  }, [bots, exchangesList])

  const botsWithErrors = useMemo(() => {
    if (isEmpty(botErrors.data)) {
      return filteredBots
    }

    return filteredBots.map(bot => ({
      ...bot,
      bot_not_enough_balance: botErrors.data[`${bot.id}-${bot.external_id.toLowerCase()}`],
    }))
  }, [filteredBots, botErrors.data])

  return {
    bots: botsWithErrors,
    isFetching,
    setBots,
    fetchBots,
    setIsFetching,
    filterBots,
    exchanges,
    isLastPage,
  }
}

export const useBotGraph = () => {
  const [graph, setGraph] = useState({ show: false, botId: null, operationId: null })

  const openGraph = (externalId, internalId, operationId, side) => {
    setGraph({
      show: true,
      internalId,
      externalId,
      operationId,
      side,
    })
  }

  const closeGraph = () => setGraph((prev) => ({ ...prev, show: false }))

  return [graph, openGraph, closeGraph]
}

export const useBotBulkActions = ({ data, setIsFetching, refreshBots }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [selectedBots, setSelectedBots] = useState([])

  const selectBot = useCallback((bot, checked) => {
    setSelectedBots(prev => {
      if (!checked) {
        return prev.filter(b => b !== bot)
      }

      return [...prev, bot]
    })
  }, [])

  const selectAllBots = useCallback((checked) => {
    setSelectedBots(checked ? data.map(({ id }) => id) : [])
  }, [setSelectedBots, data])
  const selectedAll = selectedBots.length !== 0 && selectedBots.length === data.length

  const archiveSelected = useCallback(async () => {
    if (selectedBots.length !== 0) {
      try {
        setIsFetching(true)
        setSelectedBots([])
        await botAPI.archiveBots(selectedBots)
        refreshBots()
        enqueueSnackbar(`Archived ${selectedBots.length} bots`, { variant: 'success' })
      } catch (e) {
        enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
      }
    }
  }, [selectedBots, enqueueSnackbar, setIsFetching, refreshBots])

  const enableSelected = useCallback(async () => {
    if (selectedBots.length !== 0) {
      try {
        setIsFetching(true)
        setSelectedBots([])
        await botAPI.enableBots(selectedBots)
        refreshBots()
        enqueueSnackbar(`Enabled ${selectedBots.length} bots`, { variant: 'success' })
      } catch (e) {
        enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
      }
    }
  }, [selectedBots, enqueueSnackbar, setIsFetching, refreshBots])

  const disableSelected = useCallback(async () => {
    if (selectedBots.length !== 0) {
      try {
        setIsFetching(true)
        setSelectedBots([])
        await botAPI.disableBots(selectedBots)
        refreshBots()
        enqueueSnackbar(`Disabled ${selectedBots.length} bots`, { variant: 'success' })
      } catch (e) {
        enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
      }
    }
  }, [selectedBots, enqueueSnackbar, setIsFetching, refreshBots])

  return {
    selectBot,
    selectedAll,
    selectAllBots,
    selectedBots,
    archiveSelected,
    enableSelected,
    disableSelected,
  }
}
