import { Tooltip } from '@mui/material'

import { TRIPLE_BOT_SIDES } from './BotEditor/constants'

export const MOBILE_COLUMNS = {
  firstRow: [
    {
      Header: 'DEX pair',
      accessor: 'dex_symbol_left',
      Cell: ({ value, row }) => {
        if (row.type === 'CLASSIC') {
          return value && row.dex_symbol_right ? `${value}-${row.dex_symbol_right}` : ''
        }
        const arb = row.triple_arbitrage[0]

        return arb ? `${arb.step1?.left}-${arb.step1?.right}` : null
      },
    },
    {
      Header: 'CEX pair',
      accessor: 'cex_symbol_left',
      Cell: ({ value, row }) => {
        if (row.type === 'CLASSIC') {
          return value && row.cex_symbol_right ? `${value}-${row.cex_symbol_right}` : ''
        }
        const arb = row.triple_arbitrage[0]

        return (
          arb ? (
            <div>
              <div>
                {arb.step2?.left}-{arb.step2?.right}
              </div>
              <div>
                {arb.step3?.left}-{arb.step3?.right}
              </div>
            </div>
          ) : null
        )
      },
    },
    {
      Header: 'Network',
      accessor: 'network_to',
      Cell: ({ row }) => {
        if (row.type === 'CLASSIC') {
          return row.classic_arbitrage?.[0]?.dex?.network
        }

        return row.triple_arbitrage?.[0]?.step1?.network
      },
    },
    {
      Header: 'Exchange',
      accessor: 'exchange_from.name',
      Cell: ({ value, row }) => {
        if (row.type === 'CLASSIC') {
          return value || null
        }
        const arb = row.triple_arbitrage[0]

        return arb?.step1?.exchange || null
      },
    },
    {
      Header: 'CEX',
      accessor: 'exchange_to.name',
      Cell: ({ value, row }) => {
        if (row.type === 'CLASSIC') {
          return value || null
        }
        const arb = row.triple_arbitrage[0]

        return (
          <div>
            <div>{arb?.step2?.exchange || null}</div>
            <div>{arb?.step3?.exchange || null}</div>
          </div>
        )
      },
    },
    {
      Header: 'PMin',
      accessor: 'pmin',
    },
    {
      Header: 'CEX multiplier',
      accessor: 'cex_multiplier',
    },
  ],
  secondRow: [
    {
      Header: 'Direction',
      accessor: 'direction',
      Cell: ({ value, row, ...cell }) => {
        if (row.type === 'CLASSIC') {
          return (
            <div>
              {value?.map((_value, key) => (
                <div key={_value.side + key}>{_value?.side}</div>
              ))}
            </div>
          )
        }

        const arb = row.triple_arbitrage[0]
        const sideLabel =
          arb?.side && TRIPLE_BOT_SIDES.find(({ id }) => id.toLowerCase() === arb.side.toLowerCase())?.name

        return sideLabel ? sideLabel : null
      },
    },
    {
      Header: 'Amount',
      accessor: 'qn',
      Cell: ({ value, row }) => (
        <div>
          {value?.map((_value, key) => (
            <div key={_value?.amount + key}>{_value?.amount}</div>
          ))}
        </div>
      ),
    },
    {
      Header: 'Signals',
      accessor: 'opportunities',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <div>
            {Object.values(value || {}).map(({ today, previous_days }, i) => (
              <div key={i}>{today}&nbsp;+&nbsp;{previous_days}</div>
            ))}
          </div>
        )
      },
    },
    {
      Header: 'Orders',
      accessor: 'orders',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <div>
            {Object.values(value || {}).map(({ today, previous_days }, i) => (
              <div key={i}>{today}&nbsp;+&nbsp;{previous_days}</div>
            ))}
          </div>
        )
      },
    },
    {
      Header: 'Current arbitrage',
      accessor: 'opportunity',
      Cell: ({ row, value = {}, ...cell }) => (
        <div>
          {Object.keys(value).map((key) => (
            <div key={key}>
              {parseFloat(value[key]?.profit).toFixed(3)}
              <Tooltip title='Open chart'>
                <span
                  onClick={() => {
                    cell.openGraph(row.external_id, row.id, value[key].id, value[key].side)
                  }}
                  style={{ margin: '0 4px' }}
                >
                  <i className='fas fa-chart-bar fa-lg' id='open_chart_icon' />
                </span>
              </Tooltip>
            </div>
          ))}
        </div>
      ),
    },
  ],
}
