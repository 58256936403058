import { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
// import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './App.css'
import Routes from './Routes'
import FullscreenProvider from 'providers/Fullscreen/Provider'
import { SnackbarProvider } from 'notistack'

import Layout from './containers/Layout/Layout'
import { store } from './redux/store'

const queryClient = new QueryClient()

// const persister = createSyncStoragePersister({
//   storage: window.localStorage,
// })

class AppWrap extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error boundary: ', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return (
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={5}>
          <BrowserRouter>
            <Provider store={store}>
              <FullscreenProvider>
                <Layout>
                  <Routes />
                </Layout>
              </FullscreenProvider>
            </Provider>
          </BrowserRouter>
        </SnackbarProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    )
  }
}

export default AppWrap
